(function(){

	var ativadorSombra = $('[data-sombra="true"]');

	var alvoClasse = $('body');

	var alvoClasseClassesToggle = alvoClasse.attr('data-toggle');

	ativadorSombra.on('click',function(){
		alvoClasse.addClass('sombra');
		desligar.fadeIn('500');
	});

	var desligar = $('[data-desligador="true"]');

	desligar.on('click',function(){
		alvoClasse.removeClass(alvoClasseClassesToggle);
		$(this).fadeOut('500');
	});

	var menuToggle = $('[data-menu-active="true"]');

	menuToggle.on('click',function(){
		alvoClasse.addClass('menu-active');
	});

	var formBuscasMobile = $('[data-pesquisas-mobile="true"]');
	
	formBuscasMobile.on('click',function(){
		alvoClasse.addClass('form-buscas-ativo');
	});

	var formTopo = $('[form-pesquisa-topo="true"]');

	formTopo.on('click',function(){
		alvoClasse.addClass('form-pesquisa-topo');
	});
	
})();